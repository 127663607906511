export const ROUTE_LOGIN = 'login';
export const ROUTE_HOME = 'home';
export const ROUTE_GIRO_PAY = 'giro-pay';
export const ROUTE_KLARNA = 'klarna';
export const ROUTE_HOME_CARDS = 'welcome';
export const ROUTE_REGISTER= 'register';
export const ROUTE_DISPLACEMENT = 'displacement';
export const ROUTE_GAS = 'gas';
export const ROUTE_NEWS = 'news';
export const ROUTE_PARKING_PLACE = 'parking-place';
export const ROUTE_MAINTENANCE = 'maintenance';
export const ROUTE_EVENTS = 'events';
export const ROUTE_STADIUM_EDITOR = 'stadium-editor';
export const ROUTE_STADIUM_VIEWER = 'stadium-viewer';
export const ROUTE_NOTIFICATION = 'notification';
export const ROUTE_CONFIRM_MAIL = 'confirm-mail';
export const ROUTE_ROUTES = 'routes';
export const ROUTE_DELETE_ACCOUNT = 'delete-account';
export const ROUTE_RESET_PASSWORD = 'password';
export const ROUTE_ADVERTISEMENTS = 'advertisements';

/**
 * sport views
 */
export const ROUTE_SPORT = 'sport';
export const ROUTE_SPORT_OVERVIEW = 'sport-teams';
export const ROUTE_SPORT_DETAIL = 'sport-detail';
